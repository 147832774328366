import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { format, sub } from 'date-fns';
import * as Sentry from '@sentry/react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Fade, Hide, HStack, Tab, TabList, TabPanel, TabPanels, Tabs, Tooltip, useToast } from '@chakra-ui/react';
import { Asset, AssetsView } from 'src/types/assets';
import {
  DIAGNOSTICS_FROM_DATE_FORMAT,
  DIAGNOSTICS_TO_DATE_FORMAT,
  INITIAL_FILTERS,
  NOT_MONITORED_DAYS,
  SENSOR_STATUS,
  SENSOR_TYPE,
  STATUS_CHANGED,
  TOAST_STATUS,
} from 'src/const';
import { getSiteById } from 'src/app/queries';
import { analytics } from 'src/analytics';
import { useLazyGetAssetsQuery, useLazyGetDiagnosticsQuery } from 'src/app/api/diagnosticApi';
import { setSearchValue } from 'src/app/slices/applicationSlice';
import { MachinesList } from 'src/pages/machines/components/MachinesList';
import { Toolbar } from 'src/components/Toolbar';
import { searchValueSelector } from 'src/app/store';
import { Header } from 'src/components/Header';
import Fallback from 'src/components/Fallback';
import { FilterBar } from 'src/components/filter-bar/FilterBar';
import { Loading } from 'src/components/Loading';
import {
  getAmountByCamera,
  getAmountByStatus,
  getSensorsOverallStatus,
  getTotalAssetsWithStatusChanged,
} from './utils';
import { css } from '@emotion/react';
import { SiteBusinessInsightsStatistics } from 'src/pages/machines/business-insights/SiteBusinessInsightsStatistics';
import { AssetsViewDropdown } from 'src/pages/machines/components/AssetsViewDropdown';

const sensorsAmountInitial = {
  all: 0,
  critical: 0,
  healthy: 0,
};

export const MachinesPage = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const { siteId } = useParams();
  const dispatch = useDispatch();
  const { site } = getSiteById(siteId);
  const siteLastVerified = site?.latest_diagnostic_created_at;

  const [selectedStatuses, setSelectedStatuses] = useState<Record<string, boolean>>(INITIAL_FILTERS);
  const [selectedStatusChanged, setSelectedStatusChanged] = useState<Record<string, boolean>>(STATUS_CHANGED);

  const [selectedSensors, setSelectedSensors] = useState<string>('all');
  const [totalAssetsSensors, setTotalAssetsSensors] = useState(sensorsAmountInitial);

  const selectedStatusesKeys = Object.keys(selectedStatuses).filter((key) => selectedStatuses[key]);
  const [clonedAssets, setClonedAssets] = useState<any>();
  const [fetchAssets, { data: assets, isLoading }] = useLazyGetAssetsQuery();

  const assetsAmountByStatus = useMemo(() => clonedAssets && getAmountByStatus(clonedAssets), [clonedAssets]);
  const assetsAmountByCamera = useMemo(() => clonedAssets && getAmountByCamera(clonedAssets), [clonedAssets]);

  const assetsAmountByStatusChanged = useMemo(
    () => clonedAssets && getTotalAssetsWithStatusChanged(clonedAssets, siteLastVerified),
    [clonedAssets]
  );

  const [fetchDiagnostics] = useLazyGetDiagnosticsQuery();
  const searchValue = useSelector(searchValueSelector);
  const [searchResults, setSearchResults] = useState<any>([]);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const [selectedAssetsView, setSelectedAssetsView] = useState<AssetsView>('grid');

  useEffect(() => {
    const all =
      clonedAssets &&
      clonedAssets.reduce((total: number, item: any) => {
        return total + item?.sensors_health_status?.length;
      }, 0);

    const critical =
      clonedAssets &&
      clonedAssets.reduce((total: number, item: any) => {
        return (
          total +
          item.sensors_health_status.filter((item: any) => {
            return item.health_status === SENSOR_STATUS.OFFLINE;
          }).length
        );
      }, 0);

    const healthy =
      clonedAssets &&
      clonedAssets.reduce((total: number, item: any) => {
        return (
          total +
          item.sensors_health_status.filter((item: any) => {
            return item.health_status === SENSOR_STATUS.ONLINE;
          }).length
        );
      }, 0);

    setTotalAssetsSensors({
      all,
      critical,
      healthy,
    });
  }, [clonedAssets]);

  const cutoffDate = new Date();
  cutoffDate.setDate(cutoffDate.getDate() - NOT_MONITORED_DAYS);

  const isNotMonitored = (asset: any, siteLastVerified: any) => {
    return (
      siteLastVerified &&
      (new Date(asset.latest_diagnostic_created_at) < new Date(siteLastVerified) ||
        new Date(siteLastVerified) < cutoffDate)
    );
  };

  const filterAssetsByStatusAndSensors = () => {
    if (!selectedStatuses.all) {
      return clonedAssets?.filter((item: any) => item.health_status === selectedStatusesKeys[0]);
    }

    if (selectedStatusChanged.health_status_changed) {
      return clonedAssets?.filter((item: any) => item.health_status_changed === true);
    }
    if (selectedStatusChanged.not_monitored) {
      return clonedAssets?.filter((item: any) => isNotMonitored(item, siteLastVerified));
    }

    if (selectedSensors !== 'all') {
      if (selectedSensors === SENSOR_STATUS.OFFLINE) {
        return clonedAssets?.filter(
          (item: any) => item.isSensorsOverallStatusCritical && item.sensors_health_status.length
        );
      }

      if (selectedSensors === SENSOR_STATUS.ONLINE) {
        return clonedAssets?.filter(
          (item: any) => !item.isSensorsOverallStatusCritical && item.sensors_health_status.length
        );
      }
      if (selectedSensors === SENSOR_TYPE.Camera) {
        return clonedAssets?.filter(
          (item: any) =>
            item.sensors_health_status.filter((sensor: any) => sensor.sensor_type === SENSOR_TYPE.Camera).length
        );
      }
    } else {
      return clonedAssets;
    }
  };

  const filteredAssetsByStatusAndSensors = filterAssetsByStatusAndSensors();

  const handleSearch = (value: string) => {
    const valueInLowerCase = value.toLowerCase();
    const filteredResults =
      filteredAssetsByStatusAndSensors &&
      filteredAssetsByStatusAndSensors.filter(
        (item: any) =>
          item.asset_name.toLowerCase().includes(valueInLowerCase) ||
          item.asset_ui_name.toLowerCase().includes(valueInLowerCase)
      );
    setSearchResults(filteredResults);
  };

  useEffect(() => {
    handleSearch(searchValue);
  }, [searchValue, assets, selectedStatuses, selectedStatusChanged]);

  useEffect(() => {
    dispatch(setSearchValue(''));

    analytics.page({
      name: `Machines`,
      title: 'DataMind Dashboard 2.0 - Machines',
      properties: {
        siteId,
      },
    });

    if (siteId) {
      fetchAssets(
        {
          site_id: siteId,
        },
        true
      )
        .unwrap()
        .catch((error) => {
          if (!toast.isActive(siteId)) {
            toast({
              id: siteId,
              status: TOAST_STATUS.Error,
              title: `Failed to load assets for site: ${siteId}`,
              description: t('general.errors.communication'),
            });
          }
          Sentry.captureException(
            error?.data?.message || error?.error || error?.message || error?.originalError || error
          );
        });
    }
  }, [siteId]);

  useEffect(() => {
    if (assets) {
      const assetsWithSensorsOverallStatus = assets.map((asset: Asset) => {
        return {
          ...asset,
          isSensorsOverallStatusCritical: getSensorsOverallStatus(asset.sensors_health_status),
        };
      });

      setClonedAssets([...assetsWithSensorsOverallStatus]);
    }
  }, [assets]);

  useEffect(() => {
    if (assets) {
      for (const asset of assets) {
        if (siteId) {
          const fromDate = sub(new Date(asset.latest_diagnostic_created_at), { months: 6 });
          const toDate = new Date(asset.latest_diagnostic_created_at);

          fetchDiagnostics(
            {
              site_id: siteId,
              assets_ids: asset.asset_id,
              from_date: format(fromDate, DIAGNOSTICS_FROM_DATE_FORMAT),
              to_date: format(toDate, DIAGNOSTICS_TO_DATE_FORMAT),
            },
            true
          );
        }
      }
    }
  }, [assets]);

  const onTabChange = (index: number) => setActiveTabIndex(index);
  const siteAssetsUINames = clonedAssets
    ?.map((asset: any) => asset.asset_ui_name)
    .sort((a: any, b: any) => a.localeCompare(b));

  return (
    <Suspense>
      <Header>
        <ErrorBoundary FallbackComponent={Fallback}>
          {site ? (
            <Toolbar
              siteName={site?.site_name}
              amountByStatus={assetsAmountByStatus}
              selectedStatuses={selectedStatuses}
              setSelectedStatuses={setSelectedStatuses}
              totalAssetsSensors={totalAssetsSensors}
              selectedSensors={selectedSensors}
              setSelectedSensors={setSelectedSensors}
              isTopFixed
              amountByStatusChanged={assetsAmountByStatusChanged}
              selectedStatusChanged={selectedStatusChanged}
              setSelectedStatusChanged={setSelectedStatusChanged}
              tabIndex={activeTabIndex}
              amountByCamera={assetsAmountByCamera}
            />
          ) : null}
        </ErrorBoundary>
      </Header>

      <Box {...containerProps}>
        {!isLoading && assets ? (
          <Fade in={!isLoading} transition={{ enter: { delay: 0 }, exit: { delay: 0 } }}>
            <ErrorBoundary FallbackComponent={Fallback}>
              <Hide>
                <FilterBar
                  amountByStatus={assetsAmountByStatus}
                  selectedStatuses={selectedStatuses}
                  setSelectedStatuses={setSelectedStatuses}
                  totalAssetsSensors={totalAssetsSensors}
                  selectedSensors={selectedSensors}
                  setSelectedSensors={setSelectedSensors}
                />
              </Hide>
            </ErrorBoundary>

            <Tabs index={activeTabIndex} onChange={onTabChange} variant="unstyled" isLazy>
              <HStack justifyContent="space-between">
                <TabList bgColor="#ffffff" borderRadius="8px" w="auto" border="1px solid #e2e8f0" boxShadow="sm">
                  <Tab
                    bg="#ffffff"
                    borderTopLeftRadius="8px"
                    borderBottomLeftRadius="8px"
                    borderTopRightRadius="0"
                    borderBottomRightRadius="0"
                    fontWeight={500}
                    _selected={{ color: '#12ffe2', bg: '#021d3d', fontWeight: 600 }}
                    {...tabProps}
                  >
                    Fixed Assets
                  </Tab>
                  <Tab
                    bg="#ffffff"
                    borderTopLeftRadius="8px"
                    borderBottomLeftRadius="8px"
                    borderTopRightRadius="0"
                    borderBottomRightRadius="0"
                    fontWeight={500}
                    isDisabled
                    _selected={{ color: '#12ffe2', bg: '#021d3d', fontWeight: 600 }}
                    {...tabProps}
                  >
                    <Tooltip label="Coming Soon">Mobile Assets</Tooltip>
                  </Tab>
                  <Tab
                    bg="#ffffff"
                    borderTopRightRadius="8px"
                    borderBottomRightRadius="8px"
                    borderTopLeftRadius="0"
                    borderBottomLeftRadius="0"
                    fontWeight={500}
                    _selected={{ color: '#12ffe2', bg: '#021d3d', fontWeight: 600 }}
                    {...tabProps}
                  >
                    Business Insights
                  </Tab>
                </TabList>
                <Hide below="w-1024">
                  {activeTabIndex !== 2 && (
                    <AssetsViewDropdown
                      selectedAssetsView={selectedAssetsView}
                      setSelectedAssetsView={setSelectedAssetsView}
                    />
                  )}
                </Hide>
              </HStack>

              <TabPanels h="full" w="full" bgColor="transparent">
                <Fade in={!activeTabIndex} transition={{ enter: { delay: 0 }, exit: { delay: 0 } }} unmountOnExit>
                  <TabPanel css={tabPanelStyle}>
                    <ErrorBoundary FallbackComponent={Fallback}>
                      <MachinesList assets={searchResults} site={site} selectedAssetsView={selectedAssetsView} />
                    </ErrorBoundary>
                  </TabPanel>
                </Fade>

                <Fade in={!activeTabIndex} transition={{ enter: { delay: 0 }, exit: { delay: 0 } }} unmountOnExit>
                  <TabPanel css={tabPanelStyle}>
                    <ErrorBoundary FallbackComponent={Fallback}></ErrorBoundary>
                  </TabPanel>
                </Fade>

                <Fade in={!!activeTabIndex} transition={{ enter: { delay: 0 }, exit: { delay: 0 } }} unmountOnExit>
                  <TabPanel css={tabPanelStyle}>
                    <ErrorBoundary FallbackComponent={Fallback}>
                      <SiteBusinessInsightsStatistics siteAssetsUINames={siteAssetsUINames} />
                    </ErrorBoundary>
                  </TabPanel>
                </Fade>
              </TabPanels>
            </Tabs>
          </Fade>
        ) : (
          <Loading />
        )}
      </Box>
    </Suspense>
  );
};

const tabProps = {
  fontSize: {
    base: '0.875rem',
    xl: '0.875rem',
    '1.5xl': '0.875rem',
  },
  px: {
    base: 6,
    xl: 4,
    '1.5xl': 6,
  },
  py: {
    base: 3,
    xl: 3,
    '1.5xl': 3,
  },
};

const containerProps = {
  w: 'full',
  p: { base: 0, xl: 8 },
  pt: { base: '5.5rem', xl: '5.5rem' },
  pr: 4,
};

const tabPanelStyle = css`
  padding-left: 0;
  padding-right: 0;
  padding-top: 1.5rem;
  background-color: transparent;
`;
