import { css } from '@emotion/react';
import { ComponentWithAs, IconProps } from '@chakra-ui/react';
import {
  DMStatusAlarmIcon,
  DMStatusCriticalIcon,
  DMStatusHealthyIcon,
  DMStatusMonitorIcon,
  DMStatusNotAvailableIcon,
} from 'src/components/Icons';
import { colors } from 'src/theme/foundations/colors';

export const SIDEBAR_MENU_BUTTON_SIZE = '24px';

export const SIDEBAR_MENU_BUTTON_ACTIVE_STYLE = css`
  background: linear-gradient(135deg, #7071f3 0%, #12ffe2 100%);
  color: #021d3d;
  border-radius: 100%;
`;

export enum MIME_TYPE {
  Video_MP4 = 'video/mp4',
  Image_PNG = 'image/png',
  Image_JPG = 'image/jpg',
}

export enum HEALTH_STATUS {
  CRITICAL = 'critical',
  ALARM = 'alarm',
  MONITOR = 'monitor',
  HEALTHY = 'healthy',
  NOT_AVAILABLE = 'na',
}

export enum SENSOR_STATUS {
  ONLINE = 'online',
  OFFLINE = 'offline',
}

export enum FUSION_TRENDS_TYPE {
  Machine = 'machine',
  Component = 'component',
}

export const REGULAR_DATE_FORMAT = 'dd.MM.yyyy';
export const SHORT_YEAR_DATE_FORMAT = 'dd.MM.yy';
export const DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm';
export const DATE_TIME_AM_PM_FORMAT = 'dd.MM.yyyy ha';
export const DD_MM_DATE_FORMAT = 'dd.MM';
export const YYYY_FORMAT = 'yyyy';
export const MMMM_DD_YYYY_FORMAT = 'MMMM dd, yyyy';
export const MMMM_DD_FORMAT = 'MMMM dd';

type HealthState = {
  icon: ComponentWithAs<'svg', IconProps>;
  title: string;
  description: string;
};

export const HEALTH_STATES: Record<string, HealthState> = {
  [HEALTH_STATUS.CRITICAL]: {
    icon: DMStatusCriticalIcon,
    title: 'general.legend.list.critical.title',
    description: 'general.legend.list.critical.description',
  },
  [HEALTH_STATUS.ALARM]: {
    icon: DMStatusAlarmIcon,
    title: 'general.legend.list.alarm.title',
    description: 'general.legend.list.alarm.description',
  },
  [HEALTH_STATUS.MONITOR]: {
    icon: DMStatusMonitorIcon,
    title: 'general.legend.list.monitor.title',
    description: 'general.legend.list.monitor.description',
  },
  [HEALTH_STATUS.HEALTHY]: {
    icon: DMStatusHealthyIcon,
    title: 'general.legend.list.healthy.title',
    description: 'general.legend.list.healthy.description',
  },
  [HEALTH_STATUS.NOT_AVAILABLE]: {
    icon: DMStatusNotAvailableIcon,
    title: 'general.legend.list.notAvailable.title',
    description: 'general.legend.list.notAvailable.description',
  },
  offline: {
    icon: DMStatusCriticalIcon,
    title: 'general.sensorStatus.offline',
    description: 'general.legend.list.critical.description',
  },
  online: {
    icon: DMStatusHealthyIcon,
    title: 'general.sensorStatus.online',
    description: 'general.legend.list.healthy.description',
  },
};

export const INITIAL_FILTERS = {
  all: false,
  [HEALTH_STATUS.CRITICAL]: false,
  [HEALTH_STATUS.ALARM]: false,
  [HEALTH_STATUS.MONITOR]: false,
  [HEALTH_STATUS.HEALTHY]: false,
  [HEALTH_STATUS.NOT_AVAILABLE]: false,
};

export const STATUS_CHANGED = {
  all: false,
  health_status_changed: false,
  not_monitored: false,
};

export enum TOAST_STATUS {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
}

export const FIREBASE_AUTH_ERROR: Record<string, any> = {
  'auth/wrong-password': 'Password invalid',
  EMAIL_NOT_FOUND: 'Email not found',
};

export enum MANAGEMENT_ROLE {
  DM_Admin = 'dm_admin',
  Admin = 'admin',
  Member = 'member',
}

export const userRoleLabel: Record<string, string> = {
  dm_admin: 'DM Admin',
  admin: 'Admin',
  member: 'Member',
};

export const enum VIEW_STATE {
  Failures = 'failures',
  Studio = 'studio',
}

export enum ENVIRONMENTS {
  Production = 'production',
  Development = 'development',
  Test = 'test',
}

export const plotlyColors = [
  '#1f77b4',
  '#ff7f0e',
  '#2ca02c',
  '#d62728',
  '#9467bd',
  '#8c564b',
  '#e377c2',
  '#7f7f7f',
  '#bcbd22',
  '#17becf',
];

export const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export enum SENSORS_DATA_TYPE {
  RMS = 'rms',
  FFT = 'fft',
  PI = 'pi',
  Camera = 'camera',
  SensorFusion = 'sensorFusion',
  VisualAI = 'visualAI',
}

export enum SENSOR_TYPE {
  Camera = 'camera',
  Vibrations = 'vibrations',
  Vibration = 'vibration',
}

export enum FAILURE_NAME {
  GapSize = 'gap size',
  BeltShift = 'belt shift',
  BeltShiftLeft = 'belt shift to the left',
  BeltShiftRight = 'belt shift to the right',
}

export const statusColors: Record<string, string> = {
  critical: colors.health.critical,
  alarm: colors.health.alarm,
  monitor: colors.health.monitor,
  healthy: colors.health.healthy,
  na: colors.health.notAvailable,
};

export const DIAGNOSTICS_REGULAR_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";
export const DIAGNOSTICS_FROM_DATE_FORMAT = "yyyy-MM-dd'T'00:00:00";
export const DIAGNOSTICS_TO_DATE_FORMAT = "yyyy-MM-dd'T'23:59:59";

export const SENSORS_FUSION_SELECTION_FORMAT = 'MMM d, yyyy, HH:mm';

export const START_PAGE_TOAST_ID = 'start_page';
export const MAX_POSSIBLE_DATE_ISO = '9999-01-01';
export const MIN_POSSIBLE_DATE_ISO = '-9999-01-01';

export const CAMERA_PLOT_BUFFER_VALUE = 25;

export const OTP_COUNTER_INITIAL_TIME = 600;

export const PLOTY_DIAGNOSTICS_RANGE = 20;

export const NOT_MONITORED_DAYS = 7;

export const PASSWORD_PATTERN = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

export const MIN_DATE_PICKER_SENSOR_FUSION = '2023-01-01';
