import { format } from 'date-fns';
import { DIAGNOSTICS_FROM_DATE_FORMAT, DIAGNOSTICS_REGULAR_DATE_FORMAT } from 'src/const';

export const getRollersMinMaxValues = (rollerNames: Array<string>, data: any) => {
  const entities = data.filter((entity: any) => rollerNames.includes(entity.name));
  const minValues: Array<number> = entities.map((item: any) => item.min_value);
  const maxValues: Array<number> = entities.map((item: any) => item.max_value);

  return [Math.min(...minValues), Math.max(...maxValues)];
};

export const getStepValues = (range: Array<number>) => {
  const lower = range[0] - 20;
  const higher = range[1] + 10;
  const steps: Array<number> = [];

  for (let i = lower; i < higher; i += 10) {
    steps.push(i);
  }

  return steps;
};

export const filterMeasurementsByDateRangeBeltMisalignment = (evidenceDates: any, measurements: any): any => {
  const evidenceDatesDt = evidenceDates.map((date: any) => new Date(date));
  const measurementsDt = measurements?.map((measurement: any) => new Date(measurement));

  const sortedDates = evidenceDatesDt.sort((a: any, b: any) => a - b);
  const results: any = {};

  for (let i = 0; i < sortedDates.length; i++) {
    const startDate = i === 0 ? new Date(0) : sortedDates[i - 1];
    const endDate = sortedDates[i];

    const filteredMeasurements = measurementsDt
      ?.filter(
        (measurement: any) =>
          startDate <= measurement && measurement <= new Date(endDate.getTime() + 24 * 60 * 60 * 1000 - 1)
      )
      ?.map((measurement: any) => measurement.toISOString());

    if (filteredMeasurements.length) {
      results[endDate.toISOString()] = filteredMeasurements;
    }
  }

  return results;
};

export const getLatestMeasurementsForEachDateBeltMisalignment = (results: any): any[] => {
  const latestMeasurements: any[] = [];

  for (const value of Object.values(results)) {
    if (Array.isArray(value) && value.length > 0) {
      latestMeasurements.push(value[value.length - 1]);
    }
  }

  return latestMeasurements;
};

export const filterDiagnosticsWithMeasurementsForBeltMisalignment = (
  leftFilteredResults: any,
  rightFilteredResults: any,
  diagnostics: any
) => {
  const setTimestamps = new Set([
    ...Object.entries(leftFilteredResults).map((entry) => format(new Date(entry[0]), DIAGNOSTICS_FROM_DATE_FORMAT)),
    ...Object.entries(rightFilteredResults).map((entry) => format(new Date(entry[0]), DIAGNOSTICS_FROM_DATE_FORMAT)),
  ]);

  return diagnostics.filter((diagnostic: any) => {
    return setTimestamps.has(format(new Date(diagnostic.diagnostic_created_at), DIAGNOSTICS_REGULAR_DATE_FORMAT));
  });
};
